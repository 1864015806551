@use 'sass:color';

$blue-jeans: #20a4f3;
// used this to configure Stripe branding
// had to darken to get button text color to turn white
// $blue-jeans: color.scale(#20a4f3, $blackness: 7%);
$background: #edf0f1;
$independence: #505268;
$light-rhythm: #d1d2d9;
$rich-black: #090a0b;
$body-black: #212529;
$shadow-blue: #708099;
// $shadow-blue: #5d687a;
$turquoise: #18dcc2;
$pink: #ff206e;

// sizes
$small: 576px;
$medium: 768px;
$large: 992px;
$x-large: 1200px;
$xx-large: 1400px;

// bootstrap overrides
$font-size-base: 1rem;
$font-family-base: 'Mulish', sans-serif;
$font-size-sm: $font-size-base * 0.875;
$font-weight-bold: 600;
$font-weight-bolder: 700;

$headings-font-weight: $font-weight-bold;

$dark: $rich-black;

$body-bg: $background;
$primary: $blue-jeans;
$secondary: $shadow-blue;

$accordion-bg: #fff;

$badge-border-radius: 0.3125rem;

$btn-border-radius: 1.625rem;
$btn-border-radius-sm: 1.625rem;
$btn-font-size: 1rem;
$btn-font-size-sm: 1rem;
$btn-font-weight: $font-weight-bold;
$btn-padding-x-sm: 1.875rem;
$btn-padding-y: 0.625rem;
$btn-padding-x: 2rem;
$btn-padding-x-lg: 2.5rem;
$btn-padding-y-lg: .825rem;

$card-border-radius: 1rem;

$dropdown-border-color: $light-rhythm;
$dropdown-border-radius: 1rem;
$dropdown-item-padding-x: 1.5rem;
$dropdown-item-padding-y: 0.4rem;

$form-label-font-size: 0.75rem;
$form-label-font-weight: 600;
$form-label-color: $independence;

$hr-color: $light-rhythm;
$hr-opacity: 1;

$input-bg: white;
$input-border-radius: 0.5rem;
$input-font-size: 0.875rem;
$input-padding-y: 0.5315rem;
$input-placeholder-color: $light-rhythm;

$modal-header-padding-y: 0;
$modal-footer-padding-y: 0;
$modal-footer-border-width: 0;
$modal-header-border-width: 0;

$navbar-light-color: $rich-black;
$navbar-nav-link-padding-x: 1.25rem;
