@import "variables";
// @import '~bootstrap/scss/bootstrap';
// @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;600;700&display=swap');
// @import 'sweetalert2/src/sweetalert2.scss';

.badge {
  background: #d2edfd;
  color: $blue-jeans;
}

.badge-ACTIVE {
  color: $turquoise !important;
  background-color: lighten($turquoise, 40%) !important;
}

.badge-OPTED_OUT {
  color: $pink !important;
  background-color: lighten($pink, 35%) !important;
}

.badge-BAD_PHONE_NUMBER {
  color: $pink !important;
  background-color: lighten($pink, 35%) !important;
}

.badge-DELIVERED {
  color: $turquoise !important;
  background-color: lighten($turquoise, 40%) !important;
}

.badge-ERROR {
  color: $pink !important;
  background-color: lighten($pink, 35%) !important;
}

.badge-PENDING {
  color: $shadow-blue !important;
  background-color: lighten($shadow-blue, 35%) !important;
}

.badge-QUEUED {
  color: $blue-jeans !important;
  background-color: lighten($blue-jeans, 35%) !important;
}

.badge-private {
  color: $shadow-blue !important;
  background-color: lighten($shadow-blue, 35%) !important;
  border: 1px solid $shadow-blue !important;
}

.bg-blue {
  background-color: $blue-jeans !important;
}

.bg-dark {
  background-color: $rich-black;
}

.bg-DELIVERED, .bg-SENT {
  background-color: $turquoise !important;
}

.bg-ERROR, .bg-UNDELIVERED, .bg-FAILED {
  background-color: $pink !important;
}

.bg-QUEUED, .bg-ACCEPTED, .bg-SENDING {
  background-color: $blue-jeans !important;
}

.bg-PENDING {
  background-color: lighten($shadow-blue, 20%) !important;
}

.bg-white-shadow {
  background-image: url("https://s3.us-west-2.amazonaws.com/groupbot.io/img/white-background.png");
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  height: 755px;
}

.body-light {
  color: $independence;
}

.body-lighter {
  color: $shadow-blue;
}

.body-marketing {
  color: white;
  font-size: 1rem;
  letter-spacing: -0.02em;

  @media (min-width: $medium) {
    font-size: 1.25rem;
  }
}

.btn-grow {
  transition-duration: 0.3s;
  transition-property: transform;

  &:hover,
  &:focus,
  &:not(:disabled):not(.disabled):active:not(.no-grow) {
    transform: scale(1.1);
  }
}

.btn-primary {
  color: white;
  &:active,
  &:hover,
  &:focus,
  &:disabled {
    color: white;
  }
}

.btn-check:checked + .btn-outline-primary,
.btn-check:active + .btn-outline-primary,
.btn-outline-primary:active,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:hover {
  color: white;
}

.btn-check:checked + .btn-outline-secondary,
.btn-check:active + .btn-outline-secondary,
.btn-outline-secondary:active,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show,
.btn-outline-secondary:hover {
  color: white;
}

.btn-gradient {
  background: linear-gradient(95deg, $turquoise, $blue-jeans);
  border: 0;
  border-radius: 30px;
  font-weight: 700;
}

.btn-group .btn {
  border-radius: 0.5rem;
}

.btn-outline {
  border-color: white;
  color: white;
  &:active,
  &:hover {
    background-color: white;
    color: $rich-black;
  }
}

.btn-pink {
  border-radius: 30px;
  border-color: $pink;
  background-color: $pink;
  color: white;
  &:active,
  &:hover {
    background-color: white;
    color: $rich-black;
  }
}

.btn-secondary {
  background-color: $shadow-blue;
  color: white;
  border: none;
  &:active,
  &:hover,
  &:focus {
    color: white;
    background-color: darken($shadow-blue, 10%) !important;
  }
}

.btn-short {
  line-height: 1rem !important;
}

.btn-text {
  font-weight: $font-weight-bold;
  padding: 0;
  text-transform: inherit;
}

.card {
  padding: 2rem;
  border: 0;
}

.clear-link,
.row-link,
.row-link:hover {
  text-decoration: none;
  color: inherit;
}

.color-black {
  color: $body-black !important;
}

.color-blue {
  color: $blue-jeans !important;
}

.color-dark-gray {
  color: $independence !important;
}

.color-gray {
  color: $shadow-blue !important;
}

.color-green {
  color: $turquoise !important;
}

.color-white {
  color: white !important;
}

.contact-button {
  font-size: 2rem;
}

.container-medium {
  max-width: $medium;
}

.cursor-pointer,
.trash {
  cursor: pointer;
}

.dashboard-card {
  padding: 58px;
  h1 {
    font-size: 4rem;
    color: $blue-jeans;
    // margin-bottom: 1.5rem;
  }
}

.dashed-line {
  // overflow: hidden;
  width: 100%;
  height: 2px;
  background-image: url("https://s3.us-west-2.amazonaws.com/groupbot.io/img/dashed-line.svg");
  opacity: 0.65;
}

.dashed-vert {
  // overflow: hidden;
  width: 2px;
  height: 480px;
  background-image: url("https://s3.us-west-2.amazonaws.com/groupbot.io/img/dashed-line-vert.svg");
  opacity: 0.65;
}

.divider-with-text {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid $light-rhythm;
  line-height: 0.1em;
  margin: 10px 0 20px;
  span {
    background: #fff;
    color: $shadow-blue;
    padding: 0 10px;
  }
}

.dot {
  width: 0.8125rem;
  height: 0.8125rem;
  background-color: $turquoise;
  border-radius: 100%;
}

.dropdown-item {
  font-weight: $font-weight-bold;
}

.feature-container {
  max-width: 400px;
}

.feature-img {
  max-width: 300px;
  margin-top: 3.25rem;

  @media (min-width: $small) {
    max-width: 400px;
    margin-top: 3.5rem;
  }
}

.feature-width {
  width: 100%;

  @media (min-width: $large) {
    width: 50%;
  }
}

.footer {
  font-size: 0.875rem;
  color: $shadow-blue;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.form-control {
  font-size: 1rem !important;
}

.groups {
  a {
    color: inherit;
    text-decoration: initial;
  }
  .card {
    height: 300px;
    padding-top: 26px;
  }
  .card-add {
    background-color: transparent;
    border: none;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%237d8ca3FF' stroke-width='3' stroke-dasharray='4 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 16px;
    &:hover {
      background-color: rgba(255, 255, 255, 1);
      // background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%237d8ca3FF' stroke-width='4' stroke-dasharray='4 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    }
    h4 {
      color: $shadow-blue;
    }
  }
  .stat {
    font-weight: $font-weight-bolder;
  }
  .stat-label {
    color: $shadow-blue;
  }
  .stat-value {
    color: $independence;
  }
}

h1 {
  margin-bottom: 1rem;
  font-family: "Prompt", sans-serif;
  font-weight: 700;
  color: $shadow-blue;
  font-size: 1.25rem;
  text-transform: uppercase;

}

h2 {
  font-family: "Prompt", sans-serif;
}

.h2-marketing {
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.2;

  @media (min-width: $small) {
    font-size: 2rem;
  }
  @media (min-width: $medium) {
    font-size: 2.25rem;
  }
}

h3 {
  font-family: "Prompt", sans-serif;
  //color: $independence;
}

.h3-marketing {
  color: white;
  font-weight: 700;
  font-size: 1.75rem;
  line-height: 1.2;

  @media (min-width: $small) {
    font-size: 1.75rem;
  }
  @media (min-width: $medium) {
    font-size: 2rem;
  }
}

h4 {
  font-family: "Prompt", sans-serif;
}

.h4-marketing {
  color: white;
  font-family: "Prompt", sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.2;

  @media (min-width: $small) {
    font-size: 1.5rem;
  }
  @media (min-width: $medium) {
    font-size: 1.75rem;
  }
}

h5 {
  font-family: "Prompt", sans-serif;
}

h5.number {
  color: $turquoise;
}

.how-it-works-img {
  max-width: 90px;
}

.jumbo-header {
  max-width: $medium;
  font-style: normal;
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 1.1;
  text-align: center;

  background: linear-gradient(95deg, $turquoise, $blue-jeans);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  @media (min-width: $small) {
    font-size: 4rem;
  }

  @media (min-width: $medium) {
    font-size: 6rem;
  }
}

.marquee {
  opacity: 0.85;
  position: relative;
  width: 100vw;
  max-width: 100%;
  height: 100px;
  overflow-x: hidden;
}

.mb-p75 {
  margin-bottom: .75rem;
}

.modal-content {
  border-radius: 1rem;
  border: 1px solid $shadow-blue;
}

.modal-content {
  padding: 2.6875rem 2rem 2rem 2rem;
  h2 {
    margin-bottom: 0.875rem;
  }
}

.mt--3 {
  margin-top: -1rem;
}

.navbar {
  margin-bottom: 2rem;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgb%28255, 255, 255%29%27 stroke-miterlimit=%2710%27 stroke-width=%272.5%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e");
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27%23090a0b%27 stroke-miterlimit=%2710%27 stroke-width=%272.5%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e");
}

.nav-link {
  font-weight: $font-weight-bold;
  padding: 0.75rem 1rem;
}

.navbar-toggler {
  border: none;
  box-shadow: none;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: none;
}

.opacity-25 {
  opacity: 0.25;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-75 {
  opacity: 0.75;
}

.pricing-header {
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.2;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;

  @media (min-width: $small) {
    font-size: 2.5rem;
  }

  @media (min-width: $medium) {
    font-size: 3.5rem;
  }
}

.pricing-width {
  width: 100%;

  @media (min-width: $large) {
    width: 33.33%;
  }
}

.pricing-number {
  font-family: "Prompt", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 5rem;
  line-height: 1;
  color: $turquoise;

  @media (min-width: $large) {
    font-size: 6.5rem;
  }
}

.pricing-annual {
  font-size: 1.125;
}

.progress {
  border-radius: 1rem !important;
}

.profile-circle {
  border-radius: 50%;
  background: #0097A7;
  color: white;
  letter-spacing: 0;
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 600;
  height: 40px;
  width: 40px;
  padding-bottom: 1px;
}

.profile-circle-large {
  border-radius: 50%;
  background: $independence;
  color: white;
  // letter-spacing: 2px;
  font-size: 2.75rem;
  line-height: 1;
  font-weight: 400;
  height: 8rem;
  width: 8rem;
  padding-bottom: 2px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
}

.search {
  border-radius: 1.5rem;
  padding-left: 1.5rem !important;
  background-color: #edf0f180;
}

.search::placeholder {
  color: $shadow-blue !important;
}

.section-text {
  font-size: 1.25rem;
  line-height: 1.2;
  letter-spacing: -0.02em;

  @media (min-width: $medium) {
    font-size: 1.5rem;
  }
}

.social-button {
  border: 1px solid $light-rhythm;
  border-radius: 0.5rem;
  padding: 1.25rem;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: $blue-jeans;
}

input:focus + .slider {
  box-shadow: 0 0 1px $blue-jeans;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.subtitle-2 {
  color: $shadow-blue;
  font-size: 1.125em;
}

table {
  color: $independence !important;
}


table,
thead,
tbody,
th,
tr {
  border: 0 !important;
}

td {
  padding: 1rem 1rem 1rem 0 !important;
  border: 0 !important;
  border-top: 1px solid $light-rhythm !important;
}

th {
  padding: 0rem 1rem 0.5rem 0 !important;
}


.text-sm {
  font-size: $font-size-sm;
}

.text-danger {
  text-decoration: none;
}

.w-lg-50-w-100 {
  width: 100%;

  @media (min-width: $large) {
    width: 50%;
  }
}

.w-sm-100 {
  width: 100%;
  @media (min-width: $medium) {
    width: auto;
  }
}
